var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"frag",rawName:"v-frag"}]},[_c('v-app-bar',{attrs:{"flat":"","app":"","height":"80px","extension-height":"70px"},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-tabs',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],attrs:{"grow":"","hide-slider":""}},_vm._l((_vm.menus),function(menu,index){return _c('v-tab',{key:("menu-" + index),attrs:{"exact":"","to":{ name: menu.to }}},[_vm._v(" "+_vm._s(menu.title)+" ")])}),1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.smAndDown),expression:"$vuetify.breakpoint.smAndDown"}],attrs:{"align":"center"}},[_c('auth-connector',{scopedSlots:_vm._u([{key:"loggedIn",fn:function(ref){
var logout = ref.logout;
return [_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"flat":""}},[_c('div',{staticClass:"pa-1"},[_c('profile-connector',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var profile = ref.profile;
return [_c('p',{staticClass:"mb-0"},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("far fa-user-circle")]),_c('small',[_vm._v(_vm._s(profile.username))])],1),_c('p',{staticClass:"mb-0"},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("fas fa-wallet")]),_c('small',[_vm._v(_vm._s(profile.balance))]),_c('v-btn',{attrs:{"x-small":"","icon":""},on:{"click":profile.checkBalance}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fas fa-redo")])],1)],1)]}}],null,true)})],1)])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"depressed":"","dark":"","color":"red","block":""},on:{"click":logout}},[_vm._v(" ออกจากระบบ ")])],1)]}},{key:"loggedOut",fn:function(){return [_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","block":"","to":{ name: 'Login' }}},[_vm._v(" เข้าสู่ระบบ ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"depressed":"","color":"secondary","block":"","to":{ name: 'Register' }}},[_vm._v(" สมัครสมาชิก ")])],1)]},proxy:true}])})],1)]},proxy:true}])},[_c('figure',{staticStyle:{"height":"100%"}},[_c('img',{attrs:{"height":"100%","src":"https://betflix-assets.s3.ap-southeast-1.amazonaws.com/seamless-poipet-test/assets/royale-logo.png","alt":""}})]),_c('v-spacer'),_c('v-app-bar-nav-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.smAndDown),expression:"$vuetify.breakpoint.smAndDown"}],on:{"click":function($event){_vm.dialog = !_vm.dialog}}}),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"h-100",staticStyle:{"max-width":"300px"}},[_c('auth-connector',{scopedSlots:_vm._u([{key:"loggedIn",fn:function(ref){
var logout = ref.logout;
return [_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"flat":""}},[_c('div',{staticClass:"pa-1"},[_c('profile-connector',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var profile = ref.profile;
return [_c('p',{staticClass:"mb-0"},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("far fa-user-circle")]),_c('small',[_vm._v(_vm._s(profile.username))])],1),_c('p',{staticClass:"mb-0"},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("fas fa-wallet")]),_c('small',[_vm._v(_vm._s(profile.balance))]),_c('v-btn',{attrs:{"x-small":"","icon":""},on:{"click":profile.checkBalance}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fas fa-redo")])],1)],1)]}}],null,true)})],1)])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"depressed":"","dark":"","color":"red","block":""},on:{"click":logout}},[_vm._v(" ออกจากระบบ ")])],1)]}},{key:"loggedOut",fn:function(){return [_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","block":"","to":{ name: 'Login' }}},[_vm._v(" เข้าสู่ระบบ ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"depressed":"","color":"secondary","block":"","to":{ name: 'Register' }}},[_vm._v(" สมัครสมาชิก ")])],1)]},proxy:true}])})],1)],1),_c('v-dialog',{attrs:{"fullscreen":"","transition":"dialog-top-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v(" fas fa-times ")])],1)],1),_c('v-list',_vm._l((_vm.menus),function(menu,index){return _c('v-list-item',{key:("modal-menu-" + index),attrs:{"to":{ name: menu.to },"exact":""}},[_c('v-list-item-content',[_c('center',[_vm._v(" "+_vm._s(menu.title)+" ")])],1)],1)}),1),_c('auth-connector',{scopedSlots:_vm._u([{key:"loggedIn",fn:function(ref){
var logout = ref.logout;
return [_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"depressed":"","block":"","dark":"","color":"red"},on:{"click":function () {
                logout()
                _vm.dialog = false;
              }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fas fa-sign-out-alt ")]),_vm._v(" ออกจากระบบ ")],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }