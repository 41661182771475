





































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import AuthConnector from '@/connector/Auth.vue';
import ProfileConnector from '@/connector/Profile.vue';

@Component({
  name: 'BaseNavigator',
  components: {
    AuthConnector,
    ProfileConnector,
  },
})
export default class BaseNavigator extends Vue {
  public dialog: boolean = false;

  // eslint-disable-next-line class-methods-use-this
  get menus(): Array<{ title: string; to: string }> {
    return [
      { title: 'หน้าหลัก', to: 'Home' },
      { title: 'เมนูสมาชิก', to: 'Dashboard' },
      { title: 'ฝากเงิน', to: 'Deposit' },
      { title: 'ถอนเงิน', to: 'Withdraw' },
      { title: 'โปรโมชั่น', to: 'Promotion' },
      { title: 'แนะนำเพื่อน', to: 'Affiliate' },
      { title: 'ติดต่อเรา', to: 'Contact' },
    ];
  }
}
