var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"frag",rawName:"v-frag"}]},[_vm._t("default",null,{"profile":{
      balance: _vm.balance,
      bank: _vm.bank,
      accountNumber: _vm.accountNumber,
      checkBalance: _vm.checkBalance,
      accountName: _vm.accountName,
      username: _vm.username,
      refCode: _vm.refCode,
      affiliateRevenue: _vm.affiliateRevenue,
      agentApiUsername: _vm.agentApiUsername,
      refundLoss: _vm.refundLoss,
      point: _vm.point,
      fortuneWheel: _vm.fortuneWheel,
    },"profileLoading":_vm.profileLoading})],2)}
var staticRenderFns = []

export { render, staticRenderFns }