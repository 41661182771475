/* eslint-disable */
import {
  VuexModule,
  Module,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import AuthService from '@/services/AuthService';

const authService = new AuthService();

interface LoginPayload {
  gameUrl: string;
  isLoggedIn: boolean;
  token: string;
}

@Module({ namespaced: true })
export default class Auth extends VuexModule {
  public isLoggedIn: boolean = false;

  public gameUrl: string = '';

  public token: string = '';

  @Mutation
  public setLoggedIn(payload: LoginPayload) {
    this.isLoggedIn = payload.isLoggedIn;
    this.gameUrl = payload.gameUrl;
    this.token = payload.token;
  }

  @Action({ rawError: true })
  public async login(payload: { username: string; password: string }): Promise<void> {
    console.log('payload', payload);
    const { data } = await authService.login(payload.username, payload.password);
    if (data.token) {
      this.context.commit('setLoggedIn', {
        gameUrl: data.playUrl,
        isLoggedIn: true,
        token: data.token,
      });
      await authService.storeTokenInSessionStorage(data.token);
    }
  }

  @Action({ rawError: true }) async autoLogin(): Promise<void> {
    try {
      const { data } = await authService.checkToken();
      const token = await authService.getTokenFormSessionStorage();
      this.context.dispatch('Profile/subscribeToPromotionChannal', {}, { root: true });
      this.context.commit('setLoggedIn', {
        gameUrl: data.playUrl,
        isLoggedIn: true,
        token,
      });
    } catch (e) {
      console.log('error auto login', e);
    }
  }

  @Action({ rawError: true })
  public async logout() {
    await authService.removeTokenFormSessionStorage();
    this.context.commit('setLoggedIn', {
      gameUrl: '',
      isLoggedIn: false,
    });
  }
}
