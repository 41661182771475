












import { Component, Vue } from 'vue-property-decorator';
import BaseNavigator from '@/components/BaseNavigator.vue';
import BaseFooter from '@/components/BaseFooter.vue';
import BaseSnackbar from '@/components/BaseSnackbar.vue';

@Component({
  components: {
    BaseNavigator,
    BaseFooter,
    BaseSnackbar,
  },
})
export default class BaseLayout extends Vue {

}
