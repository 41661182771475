






















import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const Auth = namespace('Auth');
const Dialog = namespace('Dialog');

@Component
export default class AuthConnector extends Vue {
  @Auth.State
  public isLoggedIn!: boolean;

  @Auth.State
  public token!: string;

  @Auth.State
  public gameUrl!: string;

  @Auth.Action
  // eslint-disable-next-line @typescript-eslint/ban-types
  public login!: (payload: object) => {}

  @Auth.Action
  // eslint-disable-next-line @typescript-eslint/ban-types
  public logout!: () => {}

  @Dialog.Action
  // eslint-disable-next-line @typescript-eslint/ban-types
  public updateDialog!: (payload: { open: boolean; dialogType: string }) => {}

  public async loginFunction(payload: { username: string; password: string }) {
    try {
      await this.login(payload);
      this.$router.push({ name: 'Dashboard' });
      this.$store.commit('Snackbar/showNotification', { message: 'ลงชื่อเข้าใช้สำเร็จ', error: false });
    } catch (e) {
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }

  public notifyLogoutOnly() {
    this.$store.commit('Snackbar/showNotification', { message: 'ท่านเป็นสมาชิกอยู่แล้ว', error: true });
  }

  // eslint-disable-next-line class-methods-use-this
  playGames() {
    window.location.href = this.gameUrl;
  }

  public async logoutFunction() {
    this.$store.dispatch('Profile/unSubscribeToPromotionChannel');
    await this.logout();
    this.$router.push({ name: 'Home' });
  }
}
