/* eslint-disable */
import { AppService } from './AbstractService';

console.log('AppService', AppService);
export default class AuthService extends AppService {
  public async login(username: string, password: string): Promise<any> {
    const payload = {
      username,
      password,
    };
    return this.makeRequest('POST', '/auth/users', payload);
  }

  public async checkToken() {
    const response = this.makeRequest('POST', '/auth/check-token');
    return response;
  }

  public async storeTokenInSessionStorage(token: string): Promise<void> {
    await this.sessionStorage.setItem('ufaauto565.com', token);
  }

  public async getTokenFormSessionStorage(): Promise<string|null> {
    const username = await this.sessionStorage.getItem('ufaauto565.com');
    return username;
  }

  public async removeTokenFormSessionStorage(): Promise<void> {
    await this.sessionStorage.removeItem('ufaauto565.com');
  }

  public async changePassword(
    oldPassword: string,
    newPassword: string,
    confirmNewPassword: string,
  ) {
    const payload = {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_new_password: confirmNewPassword,
    };
    return this.makeRequest('PATCH', '/users/change-password', payload);
  }
}
