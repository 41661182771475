/* eslint-disable */
import { AppService } from './AbstractService';

interface RegisterData {
  username: string;
  password: string;
  accountNumber: string;
  bank: string;
  accountName: string;
  lineId?: string;
  knownUsFrom: string;
  ref_code?: string|null; 
}

export default class UserService extends AppService {
  public registerUser(userData: RegisterData) {
    console.log('userData', userData);
    return this.makeRequest('POST', '/users', {
      username: userData.username,
      password: userData.password,
      account_number: userData.accountNumber,
      bank: userData.bank,
      account_name: userData.accountName,
      lineId: userData.lineId,
      known_us_from: userData.knownUsFrom,
      ref_code: userData.ref_code,
    });
  }

  public async checkBalance() {
    return this.makeRequest('GET', '/users/balance');
  }

  public async getProfile() {
    return this.makeRequest('GET', '/users/me');
  }

  public async getStats() {
    return this.makeRequest('GET', '/users/me/summary');
  }

  public async getAffliateMember() {
    return this.makeRequest('GET', '/users/me/affiliates');
  }

  public async luanchGame() {
    return this.makeRequest('POST', '/users/luanch-game');
  }
}
