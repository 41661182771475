import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
// eslint-disable-next-line import/no-cycle
import Store from '@/store';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/pages/Home.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/pages/Dashboard.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/pages/Login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/pages/Register.vue'),
  },
  {
    path: '/fortune-wheel',
    name: 'FortuneWheel',
    component: () => import('@/pages/FortuneWheel.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/transaction',
    name: 'Transaction',
    component: () => import('@/pages/Transaction.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/deposit',
    name: 'Deposit',
    component: () => import('@/pages/Deposit.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/withdraw',
    name: 'Withdraw',
    component: () => import('@/pages/Withdraw.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/promotion',
    name: 'Promotion',
    component: () => import('@/pages/Promotion.vue'),
  },
  {
    path: '/join-promotion',
    name: 'JoinPromotion',
    component: () => import('@/pages/JoinPromotion.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/cashback',
    name: 'Cashback',
    component: () => import('@/pages/Cashback.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/me',
    name: 'PlayerInfo',
    component: () => import('@/pages/PlayerInfo.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/pages/Contact.vue'),
  },
  {
    path: '/affiliate',
    name: 'Affiliate',
    component: () => import('@/pages/Affiliate.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/play',
    name: 'PlayGame',
    component: () => import('@/pages/PlayGame.vue'),
    meta: {
      requiredLogin: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.query.ref) {
    await localStorage.setItem('demo-affiliate-program-reference', String(to.query.ref));
  }
  if (to.meta?.requiredLogin) {
    if (!Store.state.Auth.isLoggedIn) {
      next({ name: 'Login' });
      return;
    }
  }
  next();
});

router.afterEach((to) => {
  if (to.query.login) {
    Store.dispatch('Dialog/updateDialog', { open: true, dialogType: 'LoginDialog' });
  }
  if (to.query.register) {
    Store.dispatch('Dialog/updateDialog', { open: true, dialogType: 'RegisterDialog' });
  }
});

export default router;
